html {
  scroll-behavior: smooth;
  width: 100vw;
  height: 100vh;
  overflow-x: overlay;
  /* overflow-y: hidden; */
 
}

body {
  margin: 0;
  font-family: "SVN-Gilroy", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-width: 2560px;
  height: 100vh;
  min-height: 1000px;
  position:relative; 
  overflow-x: overlay;
  overflow-y: hidden;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color:transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #E2E2E2;
  background-clip: padding-box;
  border-radius: 20px;
}
#root{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color:transparent;
}

html::-webkit-scrollbar-thumb {
  background-color: #E2E2E2;
  background-clip: padding-box;
  border-radius: 20px;
}

p{
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SVN-Gilroy";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/SVN-Gilroy-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SVN-Gilroy";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/SVN-Gilroy-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SVN-Gilroy";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/SVN-Gilroy-Bold.otf") format("opentype");
}

@font-face {
    font-family: "UTM Avo";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/utm-avo.ttf") format("truetype");
}

@font-face {
    font-family: "UTM Avo";
    font-weight: bold;
    font-style: normal;
    src: url("./assets/fonts/utm-avo-bold.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Display";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/FontsFree-Net-SFProDisplay-Medium.ttf") format("truetype");
}
